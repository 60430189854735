<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- LINE Pay -->
    <v-form ref="form1" v-model="valid1" class="ma-4" v-if="settings">
      <v-container v-if="settings.linepay">
        <v-row align="center">
          <v-col cols="auto">
            <span class="text-h3">LINE Pay</span>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
              </template>
              <span>加盟店MyPageの「決済連動管理」を参照し、入力してください</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" sm="6">
            <v-switch v-model="switchLinePay">
              <template v-slot:label
                ><span class="black--text">利用可能にする</span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" sm="6">
            <v-text-field
              v-model="settings.linepay.channelId"
              label="channelId"
              placeholder="channelId"
              persistent-placeholder
              outlined
              :disabled="!switchLinePay"
              :rules="rules.required"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-0" sm="6">
            <v-text-field
              v-model="settings.linepay.channelSecret"
              label="channelSecret"
              placeholder="channelSecret"
              persistent-placeholder
              outlined
              :disabled="!switchLinePay"
              :rules="rules.required"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- ボタン -->
    <v-row class="mt-16">
      <v-col class="pt-0 pb-0">
        <v-btn
          :disabled="!enableUpdateBtn(settings)"
          class="primary mt-4"
          large
          block
          @click="onClickRegister"
          >{{ btnName }}</v-btn
        >
      </v-col>
    </v-row>

    <!-- snackbar -->
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      outlined
      right
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OnlinePayment",
  components: {},
  props: {},
  data: () => ({
    // form
    valid1: false,
    rules: {
      required: [(v) => !!v || "必須入力です"],
    },

    btnName: "決済情報を更新する",

    //
    switchLinePay: false,

    // snackbar
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  computed: {
    ...mapGetters(["progress", "settings"]),
    enableUpdateBtn: function () {
      return function (settings) {
        return this.isEnableUpdate(settings);
      };
    },
  },
  watch: {
    settings: function () {
      this.applyData();
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["fetchSettings", "updateSettings"]),
    async init() {
      this.fetchSettings();
    },
    async applyData() {
      const settings = this.settings;
      console.log("settings", settings);

      this.switchLinePay = settings.onlinePayment.linepay.enabled;
    },
    async onClickRegister() {
      try {
        // 登録/更新データ作成
        const dbData = this.settings;

        // linepay, paypay
        dbData.onlinePayment.linepay.enabled = this.switchLinePay;

        // onlinepayment 有効化 (決済方法がどれか1つでも有効の場合のみ)
        const onlinePaymentEnabled = this.switchLinePay;
        dbData.onlinePayment.enabled = onlinePaymentEnabled;

        console.log("register", dbData);

        // 更新処理
        await this.updateSettings({ settings: dbData });

        this.showSnackbar("更新しました");
      } catch (e) {
        console.error(e);
        this.showSnackbar("更新に失敗しました");
      }
    },
    showSnackbar: function (text) {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = text;
    },
    isEnableUpdate(settings) {
      if (!settings) {
        return false;
      }

      // 「利用可能」選択した場合は入力チェック
      if (this.switchLinePay && !this.valid1) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.v-size--large {
  font-size: 1.4rem;
}
</style>
